import React from 'react'

import PropTypes from 'prop-types'

import './hero9.css'

const Hero9 = (props) => {
  return (
    <div className="hero9-header30 thq-section-padding">
      <div className="hero9-container">
        <img
          alt={props.ornament261Alt}
          src={props.ornament261Src}
          className="hero9-ornament261"
        />
      </div>
      <div className="hero9-max-width thq-section-max-width">
        <div className="hero9-content">
          <div className="hero9-container1">
            <img
              alt={props.imageAlt1}
              src={props.imageSrc1}
              className="hero9-image"
            />
            <h3 className="hero9-text">
              <span>Empower your</span>
              <br></br>
              <span className="hero9-text03">Business Growth</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                with cutting-edge
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>cloud solutions.</span>
            </h3>
          </div>
          <div className="hero9-actions">
            <a href="#ourservices" className="hero9-button thq-button-filled">
              <span className="hero9-text09 thq-body-small">
                {props.action1}
              </span>
            </a>
            <a href="#contactus" className="hero9-button1 thq-button-outline">
              <span className="hero9-text10 thq-body-small">
                {props.action2}
              </span>
            </a>
          </div>
          <p className="hero9-text11">{props.content1}</p>
        </div>
      </div>
    </div>
  )
}

Hero9.defaultProps = {
  imageSrc:
    'https://images.unsplash.com/photo-1542393545-10f5cde2c810?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8c2VhcmNofDQ2fHxjb21wdXRlcnxlbnwwfHx8fDE3MTgwOTY2MDV8MA&ixlib=rb-4.0.3&q=80&w=300',
  ornament261Alt: 'Ornament2613358',
  imageAlt: 'image',
  image1Alt:
    'Transform your operations with our expertise in cloud-native applications, serverless architectures, and AI-driven solutions. We help you leverage the power of the cloud to innovate, scale, and stay ahead in a competitive market.',
  heading1: 'Welcome to Cloud Computing Services',
  action1: 'Learn More',
  image1Src:
    'https://images.unsplash.com/photo-1617387248282-c791433b9871?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODA5OTQyNXw&ixlib=rb-4.0.3&q=80&w=1080',
  action2: 'Contact Us',
  ornament261Src: '/external/ornament2613358-axk-200w-200w.png',
  imageSrc1: '/imgfrontend-500w.png',
  content1: '---',
  imageAlt1: 'image',
}

Hero9.propTypes = {
  imageSrc: PropTypes.string,
  ornament261Alt: PropTypes.string,
  imageAlt: PropTypes.string,
  image1Alt: PropTypes.string,
  heading1: PropTypes.string,
  action1: PropTypes.string,
  image1Src: PropTypes.string,
  action2: PropTypes.string,
  ornament261Src: PropTypes.string,
  imageSrc1: PropTypes.string,
  content1: PropTypes.string,
  imageAlt1: PropTypes.string,
}

export default Hero9
