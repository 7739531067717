import React from 'react'

import PropTypes from 'prop-types'

import './contact3.css'

const Contact3 = (props) => {
  return (
    <div
      id="#contactus"
      className={`contact3-contact20 thq-section-padding ${props.rootClassName} `}
    >
      <div className="contact3-max-width thq-section-max-width">
        <div className="contact3-section-title">
          <div className="contact3-content">
            <h2 className="contact3-text thq-heading-2">{props.heading1}</h2>
            <span className="contact3-email thq-body-small">
              {props.email1}
            </span>
            <p className="contact3-text1 thq-body-large">{props.content1}</p>
          </div>
        </div>
        <div className="contact3-row"></div>
      </div>
    </div>
  )
}

Contact3.defaultProps = {
  content3:
    'You can also fill out the form below and we will get back to you as soon as possible.',
  content4: 'Follow us on social media for updates and insights.',
  address1: '123 Cloud Way, Cloud City, 12345',
  content5: 'We look forward to hearing from you!',
  content2: 'We are available Monday to Friday, from 9:00 AM to 5:00 PM.',
  email1: 'team@questline.co',
  rootClassName: '',
  phone1: '+123-456-7890',
  heading1: 'Contact Us',
  content1:
    'Feel free to reach out to us for any inquiries or to discuss how we can assist you with your cloud computing needs. You can also fill out the form below and we will get back to you as soon as possible.',
}

Contact3.propTypes = {
  content3: PropTypes.string,
  content4: PropTypes.string,
  address1: PropTypes.string,
  content5: PropTypes.string,
  content2: PropTypes.string,
  email1: PropTypes.string,
  rootClassName: PropTypes.string,
  phone1: PropTypes.string,
  heading1: PropTypes.string,
  content1: PropTypes.string,
}

export default Contact3
