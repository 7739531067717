import React from 'react'

import PropTypes from 'prop-types'

import Features23 from './features23'
import './features1.css'

const Features1 = (props) => {
  return (
    <div id="ourservices" className="features1-layout251 thq-section-padding">
      <div className="features1-container">
        <div className="features1-column thq-flex-column">
          <span className="features1-text HeadingH4-25">{props.slogan}</span>
          <h2 className="features1-text1">{props.sectionTitle}</h2>
        </div>
      </div>
      <Features23></Features23>
    </div>
  )
}

Features1.defaultProps = {
  feature1Description: 'Our Services',
  feature3ImageSrc:
    'https://images.unsplash.com/photo-1558942077-32bb54aac140?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODA5OTQyNXw&ixlib=rb-4.0.3&q=80&w=1080',
  slogan: 'Our Services',
  sectionTitle: 'High-impact services for your business',
  feature2Title: 'Data Security',
  feature2Description:
    'We prioritize the security of your data with advanced encryption and security measures.',
  feature1ImageAlt: 'Scalability Icon',
  feature3Description:
    'Get round-the-clock support from our team of experts to ensure smooth operations.',
  feature1Title: 'Scalability',
  feature3Title: '24/7 Support',
  sectionDescription:
    'Discover the top features that set us apart from the competition.',
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1454991417399-8ef05668aee4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODA5OTQyNXw&ixlib=rb-4.0.3&q=80&w=1080',
  secondaryAction: 'Learn More',
  feature2ImageAlt: 'Data Security Icon',
  mainAction: 'Cloud Computing Solutions',
  feature2ImageSrc:
    'https://images.unsplash.com/photo-1556269923-e0c6bfab3dc9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODA5OTQyNXw&ixlib=rb-4.0.3&q=80&w=1080',
  feature3ImageAlt: 'Support Icon',
}

Features1.propTypes = {
  feature1Description: PropTypes.string,
  feature3ImageSrc: PropTypes.string,
  slogan: PropTypes.string,
  sectionTitle: PropTypes.string,
  feature2Title: PropTypes.string,
  feature2Description: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  feature3Description: PropTypes.string,
  feature1Title: PropTypes.string,
  feature3Title: PropTypes.string,
  sectionDescription: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  secondaryAction: PropTypes.string,
  feature2ImageAlt: PropTypes.string,
  mainAction: PropTypes.string,
  feature2ImageSrc: PropTypes.string,
  feature3ImageAlt: PropTypes.string,
}

export default Features1
