import React, { useState } from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './contact-form11.css'

const ContactForm11 = (props) => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <div
      id="contactus"
      className="contact-form11-contact11 thq-section-padding"
    >
      <div className="contact-form11-container">
        <div className="contact-form11-container1">
          <Script
            html={`<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSccaPKO6gYCSB3_G4uJYJxYD2Vqork9nIrnKGqHISDqSdsCVA/viewform?embedded=true" width="640" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

ContactForm11.defaultProps = {
  email: 'Enter your email',
  phone: 'Enter your phone number',
  imageSrc: 'https://play.teleporthq.io/static/svg/default-img.svg',
  successMessage:
    'Thank you for your message! Someone from the QuestLine team will respond to you soon.',
  address: 'Enter your address',
  imageAlt: 'image',
  submitAction: 'Submit',
  text: '-',
}

ContactForm11.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  imageSrc: PropTypes.string,
  successMessage: PropTypes.string,
  address: PropTypes.string,
  imageAlt: PropTypes.string,
  submitAction: PropTypes.string,
  text: PropTypes.string,
}

export default ContactForm11
