import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer1.css'

const Footer1 = (props) => {
  return (
    <footer
      className={`footer1-footer1 thq-section-padding ${props.rootClassName} `}
    >
      <div className="footer1-max-width thq-section-max-width">
        <div className="footer1-content">
          <div className="footer1-newsletter">
            <img
              alt={props.imageAlt}
              src={props.imageSrc}
              className="footer1-image"
            />
            <span className="footer1-content1">
              We’re a team of cloud professionals and innovators, united in our
              focus and pursuit of helping businesses to leverage the full power
              of the cloud.
            </span>
            <div className="footer1-actions">
              <span className="footer1-content2">{props.content2}</span>
            </div>
          </div>
          <div className="footer1-links">
            <div className="footer1-column1">
              <strong className="footer1-column1-title thq-body-large">
                {props.column1Title}
              </strong>
              <div className="footer1-footer-links">
                <Link to="/" className="footer1-link1 thq-body-small">
                  {props.link1}
                </Link>
                <a href="#ourservices" className="footer1-link2 thq-body-small">
                  {props.link2}
                </a>
                <a href="#contactus" className="footer1-link4 thq-body-small">
                  {props.link4}
                </a>
              </div>
            </div>
            <div className="footer1-column3">
              <strong className="footer1-social-link1-title">
                {props.socialLinkTitleCategory}
              </strong>
              <div className="footer1-social-links">
                <div className="footer1-link">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="footer1-icon thq-icon-small"
                  >
                    <path
                      d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                      className=""
                    ></path>
                  </svg>
                  <a
                    href="https://www.linkedin.com/company/questline-consulting"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="footer1-social-link4 thq-body-small"
                  >
                    LinkedIn
                  </a>
                </div>
                <div className="footer1-link1">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="footer1-icon2 thq-icon-small"
                  >
                    <path
                      d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"
                      className=""
                    ></path>
                  </svg>
                  <a
                    href="https://www.youtube.com/@QuestLineConsulting"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="footer1-social-link5"
                  >
                    Youtube
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer1.defaultProps = {
  content2: '© 2024 QuestLine Consulting. All rights reserved.',
  link3: 'About Us',
  action1: 'Subscribe to Newsletter',
  link7: 'FAQs',
  logoAlt: 'Cloud Computing Professional Logo',
  link5: 'Privacy Policy',
  link6: 'Terms of Service',
  cookiesLink: '/cookies-policy',
  link2: 'Services',
  logoSrc: 'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  link9: 'Get in Touch',
  link1: 'Home',
  content3: 'Designed by Your Company Name',
  column2Title: 'Contact',
  termsLink: '/terms-of-service',
  link8: 'Blog',
  rootClassName: '',
  link4: 'Contact Us',
  imageAlt: 'image',
  socialLinkTitleCategory: 'Follow Us',
  privacyLink: '/privacy-policy',
  link10: 'Client Login',
  imageSrc: '/logowhite2-200h.png',
  column1Title: 'Services',
}

Footer1.propTypes = {
  content2: PropTypes.string,
  link3: PropTypes.string,
  action1: PropTypes.string,
  link7: PropTypes.string,
  logoAlt: PropTypes.string,
  link5: PropTypes.string,
  link6: PropTypes.string,
  cookiesLink: PropTypes.string,
  link2: PropTypes.string,
  logoSrc: PropTypes.string,
  link9: PropTypes.string,
  link1: PropTypes.string,
  content3: PropTypes.string,
  column2Title: PropTypes.string,
  termsLink: PropTypes.string,
  link8: PropTypes.string,
  rootClassName: PropTypes.string,
  link4: PropTypes.string,
  imageAlt: PropTypes.string,
  socialLinkTitleCategory: PropTypes.string,
  privacyLink: PropTypes.string,
  link10: PropTypes.string,
  imageSrc: PropTypes.string,
  column1Title: PropTypes.string,
}

export default Footer1
