import React from 'react'

import PropTypes from 'prop-types'

import './features23.css'

const Features23 = (props) => {
  return (
    <div className="features23-layout349 thq-section-padding">
      <div className="features23-max-width thq-section-max-width">
        <div className="thq-grid-3">
          <div className="features23-container1 thq-card">
            <img
              alt={props.feature1ImageAlt}
              src={props.feature1ImageSrc}
              className="features23-image thq-img-round"
            />
            <h2 className="HeadingH5-22">Cloud Consulting</h2>
            <span className="features23-text01 thq-body-small">
              {props.feature1Description}
            </span>
          </div>
          <div className="features23-container2 thq-card">
            <img
              alt={props.feature2ImageAlt}
              src={props.feature2ImageSrc}
              className="features23-image1 thq-img-round"
            />
            <h2 className="HeadingH5-22">{props.feature2Title}</h2>
            <span className="features23-text03 thq-body-small">
              {props.feature2Description}
            </span>
          </div>
          <div className="features23-container3 thq-card">
            <img
              alt={props.feature3ImageAlt}
              src={props.feature3ImageSrc}
              className="features23-image2 thq-img-round"
            />
            <h2 className="HeadingH5-22">{props.feature3Title}</h2>
            <span className="features23-text05 thq-body-small">
              {props.feature3Description}
            </span>
          </div>
          <div className="features23-container4 thq-card">
            <img
              alt={props.feature4ImageAlt}
              src={props.feature4ImageSrc}
              className="features23-image3 thq-img-round"
            />
            <h2 className="HeadingH5-22">{props.feature4Title}</h2>
            <span className="features23-text07 thq-body-small">
              {props.feature4Description}
            </span>
          </div>
          <div className="features23-container5 thq-card">
            <img
              alt={props.feature5ImageAlt}
              src={props.feature5ImageSrc}
              className="features23-image4 thq-img-round"
            />
            <h2 className="HeadingH5-22">{props.feature5Title}</h2>
            <span className="features23-text09 thq-body-small">
              {props.feature5Description}
            </span>
          </div>
          <div className="features23-container6 thq-card">
            <img
              alt={props.feature6ImageAlt}
              src={props.feature6ImageSrc}
              className="features23-image5 thq-img-round"
            />
            <h2 className="HeadingH5-22">{props.feature6Title}</h2>
            <span className="features23-text11 thq-body-small">
              {props.feature6Description}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Features23.defaultProps = {
  feature2ImageAlt: 'Data Security Image',
  feature6ImageSrc:
    'https://images.unsplash.com/photo-1586350150312-bc4eb01d4990?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODExNTczMHw&ixlib=rb-4.0.3&q=80&w=1400',
  feature4Description:
    'We excel in integrating cutting-edge AI and LLM technologies into cloud applications, enabling prompt engineering solutions that drive growth and innovation for businesses.',
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1510765729892-39b1c9b3b673?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODExNTczMHw&ixlib=rb-4.0.3&q=80&w=1400',
  feature2Title: 'Cloud-Native App Dev',
  feature5Title: 'DevOps Implementation',
  feature6ImageAlt: 'Custom Solutions Image',
  feature4ImageAlt: '24/7 Support Image',
  feature4Title: 'AI/LLM Integration',
  feature1ImageAlt: 'Cloud Computing Image',
  feature1Description:
    'Our team at QuestLine Consulting specializes in guiding companies through the process of evaluating cloud solutions to drive innovation and growth.',
  feature3ImageSrc:
    'https://images.unsplash.com/photo-1566996694954-90b052c413c4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODExNTczMXw&ixlib=rb-4.0.3&q=80&w=1400',
  feature3Title: 'Serverless Automation',
  feature5ImageAlt: 'Reliability Image',
  heading1: 'Cutting-Edge Cloud Solutions for Your Business',
  feature3ImageAlt: 'Scalability Image',
  feature6Title: 'Infrastructure as Code',
  feature6Description:
    'We make deploying your customized solutions as easy as possible using Infrastructure as Code, integrating cloud security and optimization best practices.',
  feature2Description:
    'Transform your business with our Cloud-Native application development services designed to optimize performance, scalability, and innovation.',
  feature4ImageSrc:
    'https://images.unsplash.com/photo-1506399558188-acca6f8cbf41?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODExNTczMHw&ixlib=rb-4.0.3&q=80&w=1400',
  feature5ImageSrc:
    'https://images.unsplash.com/photo-1616812828442-f5d7c2777f92?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODExNTczMHw&ixlib=rb-4.0.3&q=80&w=1400',
  feature3Description:
    'Streamline your business operations with cloud integrations and event-driven workflows. Harness the power of the cloud to optimize processes and drive efficiency.',
  feature5Description:
    'We will set up continuous integration and deployment pipelines to automate the software development lifecycle, enhance collaboration, and improve code quality.',
  feature1Title: 'Cloud Consulting',
  feature2ImageSrc:
    'https://images.unsplash.com/photo-1558486012-817176f84c6d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxODExNTcyOXw&ixlib=rb-4.0.3&q=80&w=1400',
  content1:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla.',
  action1: 'Contact Us',
}

Features23.propTypes = {
  feature2ImageAlt: PropTypes.string,
  feature6ImageSrc: PropTypes.string,
  feature4Description: PropTypes.string,
  feature1ImageSrc: PropTypes.string,
  feature2Title: PropTypes.string,
  feature5Title: PropTypes.string,
  feature6ImageAlt: PropTypes.string,
  feature4ImageAlt: PropTypes.string,
  feature4Title: PropTypes.string,
  feature1ImageAlt: PropTypes.string,
  feature1Description: PropTypes.string,
  feature3ImageSrc: PropTypes.string,
  feature3Title: PropTypes.string,
  feature5ImageAlt: PropTypes.string,
  heading1: PropTypes.string,
  feature3ImageAlt: PropTypes.string,
  feature6Title: PropTypes.string,
  feature6Description: PropTypes.string,
  feature2Description: PropTypes.string,
  feature4ImageSrc: PropTypes.string,
  feature5ImageSrc: PropTypes.string,
  feature3Description: PropTypes.string,
  feature5Description: PropTypes.string,
  feature1Title: PropTypes.string,
  feature2ImageSrc: PropTypes.string,
  content1: PropTypes.string,
  action1: PropTypes.string,
}

export default Features23
